import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditionPatientDialogInterface } from '../../../../../../../shared/types/dialog.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogComponent } from '../../../../../../../shared/component/dialog/dialog.component';
import { AlertService } from '../../../../../../../shared/services/alert.service';
import { DialogService } from '../../../../../../../shared/services/dialog.service';
import { TableKeyItem, TableKeyItemWithCode } from '../../../../../../../shared/types/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../../../../../shared/services/loading.service';
import { PatientHandlingAndManagementInput } from '@smartsoft-types/sisem-incidents';
import { IncidentsService } from '../../../../../../../shared/services/incidents/incidents.service';
import { IncidentsEventsService } from '../../../../../../../shared/services/incidents/incidents-sub-resources/incidents.events.service';

import { ProfileService } from '../../../../../../../shared/services/profile/profile.service';
import { OfficeKeyItem } from 'src/app/shared/types/tabs.interface';
import { LocationsService } from 'src/app/shared/services/locations.service';
import { OfficeService } from 'src/app/shared/services/office.service';
import { PermissionsValidatorService } from '../../../../../../../shared/services/roles-and-permissions/permissions-validator.service';

@Component({
  selector: 'app-management-addressing',
  templateUrl: './management-addressing.component.html',
  styleUrls: ['./management-addressing.component.scss'],
})
export class ManagementAddressingComponent implements OnInit, OnDestroy {
  @ViewChild('requiresResource', { static: false, read: ElementRef })
  requiresResource: ElementRef;
  @ViewChild('hiddenInput') hiddenInput: ElementRef;
  @ViewChild('diagnosticValidation', { static: false, read: ElementRef })
  diagnosticValidation: ElementRef;
  title: string = '';
  public diagnosis: TableKeyItemWithCode[] = [];
  public resources: TableKeyItemWithCode[] = [];
  public registerManagementAddressingForm: FormGroup;
  public managementAddressingFormTouched: boolean = false;
  public alertIPSActive: boolean = false;
  public saveData: boolean = false;
  private subscriptionNameKeyCallback: string = '';
  public hasPriority: Array<{ name: string; priority: boolean }> = [
    {
      name: 'handlingRecommendationsAsPriority',
      priority: false,
    },
    {
      name: 'reasonForNoTransferRequiredAsPriority',
      priority: false,
    },
  ];

  public readonly formNames: { [k: string]: string } = {
    validatePrincipalDiagnosis: 'Validar diagnóstico principal',
    noTransferReason: 'Razón de no mérito de traslado',
    noTransferCause: 'No mérito de traslado',
    requiredResourceAPH: 'Tipo de recurso',
    destinationIPS: 'IPS destino',
    transferRequired: 'Amerita traslado',
  };

  public filteredIps: OfficeKeyItem[] = [];
  public bogCityID: number = -1;
  public capacityTypes: number[] = [];
  public canContinue: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditionPatientDialogInterface,
    private clipboard: Clipboard,
    private locationsService: LocationsService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<DialogComponent>,
    private alertService: AlertService,
    private incidentService: IncidentsService,
    public dialogService: DialogService,
    private incidentsEventsService: IncidentsEventsService,
    private profileService: ProfileService,
    private officesService: OfficeService,
    private permissionsValidatorService: PermissionsValidatorService
  ) {
    this.title = data.title;
    setTimeout(() => {
      this.subscriptionNameKeyCallback = this.incidentService.tabs.addCallbackForKeys(
        async (keyCode: number) => {
          if (keyCode === 123 && !this.saveData) {
            await this.saveHandlingRouting();
          }
        }
      );
    }, 100);
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.setLoading(true);
    try {
      this.initForm();
      await this.getCapacityTypes();
      await this.getBogCityId();
      await this.filterIps();
      this.setRoleRestrictions();
      this.getDiagnosis();
      this.updateFormValues();
    } catch (e) {
      console.error(e);
      this.alertService.showError('Ocurrió un error inesperado');
    }
    this.loadingService.setLoading(false);
    this.dialogRef.disableClose = true;
  }

  async filterIps() {
    const resp = (
      await this.officesService._fetchNewData(
        this.bogCityID,
        undefined,
        true,
        this.capacityTypes,
        'active'
      )
    ).filter((ips) => ips.cityOfficeId === this.bogCityID);

    this.filteredIps = resp.map((ips) => ({
      ...ips,
      title: ips.businessName,
    }));

    this.filteredIps.sort((a, b) => a.title.localeCompare(b.title));
  }

  async getBogCityId() {
    this.bogCityID = this.locationsService.locations.find(
      (location) => location.code === 'BOG'
    )!.id;
  }

  async getCapacityTypes() {
    this.capacityTypes = this.officesService.capacityTypes
      ? this.officesService.capacityTypes.filter((ct) => ct.code === 'urgencies').map((ct) => ct.id)
      : [];
  }

  private async setRoleRestrictions() {
    if (!(await this.permissionsValidatorService.hasAccessToSection(['handling_management_w']))) {
      this.registerManagementAddressingForm.disable();
    } else {
      this.registerManagementAddressingForm.enable();
      // force this because enable form's function from previous step is enabling all controls
      this.setDefaultDisabledControls();
    }
  }

  /**
   * The function initializes the form with the default values and validators
   */
  initForm() {
    this.registerManagementAddressingForm = new FormGroup({
      principalDiagnosis: new FormControl({ value: '', disabled: true }),
      validatePrincipalDiagnosis: new FormControl(
        { value: '', disabled: false },
        Validators.required
      ),
      optionalDiagnosis1: new FormControl({ value: '', disabled: true }),
      validateOptionalDiagnosis: new FormControl(''),
      optionalDiagnosis2: new FormControl({ value: '', disabled: true }),
      validateOptionalDiagnosis2: new FormControl(''),
      managementIndications: new FormControl(''),
      transferRequired: new FormControl(2, Validators.required),
      destinationIPS: new FormControl(''),
      IPSAddress: new FormControl({ value: '', disabled: true }),
      contactPhone: new FormControl({ value: '', disabled: true }),
      noTransferReason: new FormControl(''),
      noTransferCause: new FormControl(''),
      requiredResourceAPH: new FormControl(''),
    });
    this.hiddenInput?.nativeElement?.focus();
  }

  /**
   * It takes the diagnosis array from the data object, maps over it, and returns a new array with the id, code, and title
   * of each diagnosis
   */
  private getDiagnosis() {
    this.diagnosis = this.data.tabData.diagnosis.map((diagnostic) => ({
      id: diagnostic.id,
      code: diagnostic.code,
      title: `${diagnostic.code} - ${diagnostic.title}`,
    }));
  }

  /**
   * It updates the form values with the data from the patient object
   */
  private updateFormValues() {
    if (this.data.patient?.diagnosticImpression?.id) {
      this.registerManagementAddressingForm
        .get('principalDiagnosis')
        ?.setValue(this.data.patient?.diagnosticImpression?.id || '');
      this.registerManagementAddressingForm
        .get('validatePrincipalDiagnosis')
        ?.setValue(this.data.patient?.diagnosticImpression?.id || '');
    }
    if (this.data.patient?.secondDiagnosis?.id) {
      this.registerManagementAddressingForm
        .get('optionalDiagnosis1')
        ?.setValue(this.data.patient?.secondDiagnosis?.id || '');
      this.registerManagementAddressingForm
        .get('validateOptionalDiagnosis')
        ?.setValue(this.data.patient?.secondDiagnosis?.id || '');
    }
    if (this.data.patient?.thirdDiagnosis?.id) {
      this.registerManagementAddressingForm
        .get('optionalDiagnosis2')
        ?.setValue(this.data.patient?.thirdDiagnosis?.id || '');
      this.registerManagementAddressingForm
        .get('validateOptionalDiagnosis2')
        ?.setValue(this.data.patient?.thirdDiagnosis?.id || '');
    }
    this.registerManagementAddressingForm
      .get('managementIndications')
      ?.setValue(this.data.patient?.handlingRecommendations || '');
    if (
      !this.data.patient?.reasonForNoTransferRequired &&
      !this.data.patient?.noTransferCause?.id &&
      !this.data.patient?.transferToIPSId &&
      !this.data.patient?.handlingResourceTypeId
    ) {
      this.registerManagementAddressingForm.get('transferRequired')?.setValue(undefined);
      this.registerManagementAddressingForm
        .get('transferRequired')
        ?.setValidators([Validators.required]);
    } else {
      this.registerManagementAddressingForm
        .get('transferRequired')
        ?.setValue(this.data.patient?.transferRequired ? 1 : 2);
    }
    if (this.data.patient?.transferRequired) {
      this.registerManagementAddressingForm.get('noTransferReason')?.setValue('');
      this.registerManagementAddressingForm.get('noTransferCause')?.setValue('');
      this.registerManagementAddressingForm
        .get('requiredResourceAPH')
        ?.setValue(this.data.patient?.handlingResourceTypeId || '');
      this.registerManagementAddressingForm
        .get('requiredResourceAPH')
        ?.setValidators([Validators.required]);
      this.registerManagementAddressingForm.get('requiredResourceAPH')?.updateValueAndValidity();
      if (this.data.patient?.transferToIPSId) {
        const provider = this.filteredIps.find(
          (provider) => provider.id === this.data?.patient?.transferToIPSId
        );
        this.registerManagementAddressingForm
          .get('destinationIPS')
          ?.setValue(this.data.patient?.transferToIPSId || '');
        if (provider) {
          this.registerManagementAddressingForm.get('IPSAddress')?.setValue(provider.addressOffice);
          if (provider.contactData && provider.contactData[0].cellphone !== null) {
            this.registerManagementAddressingForm
              .get('contactPhone')
              ?.setValue(provider.contactData ? provider.contactData[0].cellphone : '');
          } else {
            this.registerManagementAddressingForm
              .get('contactPhone')
              ?.setValue(provider.contactData ? provider.contactData[1].cellphone : '');
          }
          this.registerManagementAddressingForm.get('contactPhone')?.disable();
          this.registerManagementAddressingForm.get('IPSAddress')?.disable();
        }
      }
    } else {
      const { noTransferCauses, noTransferCausesAll } = this.data.tabData;
      const isInclude = noTransferCauses.some(
        (item) => item.id === this.data.patient?.noTransferCause?.id
      );

      if (!isInclude && this.data.patient?.noTransferCause?.id) {
        const itemInactiveNoTransferCause = noTransferCausesAll?.find(
          (item) => item.id === this.data.patient?.noTransferCause?.id
        );

        if (itemInactiveNoTransferCause) {
          noTransferCauses.push(itemInactiveNoTransferCause);
        }
      }

      this.registerManagementAddressingForm.get('IPSAddress')?.setValue('');
      this.registerManagementAddressingForm.get('contactPhone')?.setValue('');
      this.registerManagementAddressingForm.get('destinationIPS')?.setValue('');
      this.registerManagementAddressingForm.get('requiredResourceAPH')?.setValue('');
      this.registerManagementAddressingForm
        .get('noTransferReason')
        ?.setValue(this.data.patient?.reasonForNoTransferRequired || '');
      this.registerManagementAddressingForm
        .get('noTransferCause')
        ?.setValue(this.data.patient?.noTransferCause?.id || '');
    }
    if (this.data.patient?.handlingRecommendationsAsPriority) this.hasPriority[0].priority = true;
    if (this.data.patient?.reasonForNoTransferRequiredAsPriority)
      this.hasPriority[1].priority = true;
    this.alertIPSActive = this.data.patient ? this.data.patient.alertIPS : false;
    this.changedTransferRequired();
  }

  /**
   * This function is called when the user clicks on the priority icon. It stops the event from propagating, and then
   * toggles the priority boolean value
   * @param {any} evt - any - this is the event that is triggered when the user clicks on the star.
   * @param {number} position - number - This is the index of the comment in the array.
   */
  public priorityComment(evt: any, position: number) {
    evt.stopPropagation();
    this.hasPriority[position].priority = !this.hasPriority[position].priority;
  }

  /**
   * It returns the first element in the provided list that has an id property equal to the provided filterId
   * @param {number} filterId - The id of the item you want to find.
   * @param {TableKeyItem[]} list - TableKeyItem[] - this is the list of items that we want to search through.
   * @returns The first element in the array that matches the condition.
   */
  public findElement(filterId: number, list: TableKeyItem[]): TableKeyItem | undefined {
    return list.find((value) => value.id === filterId);
  }

  /**
   * It sets the value of the principalDiagnosis form control to the value of the principalDiagnosisId parameter
   * @param {number | string} principalDiagnosisId - number | string
   */
  setPrincipalDiagnosis(principalDiagnosisId: number | string): void {
    this.registerManagementAddressingForm.get('principalDiagnosis')?.setValue(principalDiagnosisId);
  }

  /**
   * It sets the value of the validatePrincipalDiagnosis form control to the value of the principalDiagnosisId parameter
   * @param {number | string} principalDiagnosisId - number | string
   */
  setValidatePrincipalDiagnosis(principalDiagnosisId: number | string): void {
    this.registerManagementAddressingForm
      .get('validatePrincipalDiagnosis')
      ?.setValue(principalDiagnosisId);
  }

  /**
   * The function takes a number or a string as an argument and sets the value of the optionalDiagnosis1 form control to
   * the argument
   * @param {number | string} optionalDiagnosis1 - number | string
   */
  setOptionalDiagnosis1(optionalDiagnosis1: number | string): void {
    this.registerManagementAddressingForm.get('optionalDiagnosis1')?.setValue(optionalDiagnosis1);
  }

  /**
   * The function sets the value of the validateOptionalDiagnosis1 form control to the value of the optionalDiagnosis1
   * parameter
   * @param {number | string} optionalDiagnosis1 - number | string
   */
  setValidateOptionalDiagnosis1(optionalDiagnosis1: number | string): void {
    this.registerManagementAddressingForm
      .get('validateOptionalDiagnosis')
      ?.setValue(optionalDiagnosis1);
  }

  /**
   * The function takes a number or a string as an argument and sets the value of the optionalDiagnosis2 form control to
   * the argument
   * @param {number | string} optionalDiagnosis2 - number | string
   */
  setOptionalDiagnosis2(optionalDiagnosis2: number | string): void {
    this.registerManagementAddressingForm.get('optionalDiagnosis2')?.setValue(optionalDiagnosis2);
  }

  /**
   * The function takes in a number or a string and sets the value of the form control validateOptionalDiagnosis2 to the
   * value of the parameter optionalDiagnosis2
   * @param {number | string} optionalDiagnosis2 - number | string
   */
  setValidateOptionalDiagnosis2(optionalDiagnosis2: number | string): void {
    this.registerManagementAddressingForm
      .get('validateOptionalDiagnosis2')
      ?.setValue(optionalDiagnosis2);
  }

  /**
   * It sets the value of the noTransferCause form control to the value of the noTransferCauseId parameter
   * @param {number | string} noTransferCauseId - number | string - the id of the no transfer cause
   */
  setNoTransferCause(noTransferCauseId: number | string): void {
    this.registerManagementAddressingForm.get('noTransferCause')?.setValue(noTransferCauseId);
  }

  /**
   * It sets the value of the form control 'requiredResourceAPH' to the value of the parameter 'aphTypeId' and if the type
   * of the parameter is not a string, it shows a notification
   * @param {number | string} aphTypeId - number | string
   */
  setResourceAphType(aphTypeId: number | string): void {
    this.registerManagementAddressingForm.get('requiredResourceAPH')?.setValue(aphTypeId);
    if (typeof aphTypeId !== 'string')
      this.alertService.showNotification('SOLICITUD DE RECURSO TRANSFERIDA A DESPACHO');
  }

  /**
   * The function takes a MouseEvent as an argument, and returns void
   * @param {MouseEvent} evt - MouseEvent - this is the event that is triggered when the user clicks on the button.
   */
  alertIPS(evt: MouseEvent): void {
    evt.stopPropagation();
    this.alertIPSActive = !this.alertIPSActive;
    if (this.alertIPSActive)
      this.alertService.showNotification(
        'MENSAJE ENVIADO A TARM DE REFERENCIA Y A DESPACHADOR DE EMERGENCIAS'
      );
  }

  ngOnDestroy() {
    if (this.subscriptionNameKeyCallback) {
      this.incidentService.tabs.deleteCallbackForKeys(this.subscriptionNameKeyCallback);
    }
  }

  /**
   * The function is called when the user clicks on the close button of the dialog
   * @param {string} [icon] - string - This is the icon that is passed from the close button.
   */
  async closeDialog(icon?: string) {
    let forceClose: string = '';
    if (this.registerManagementAddressingForm.dirty) {
      let message = '¿Está seguro de cancelar? Los cambios realizados serán descartados';
      if (icon === 'highlight_off')
        message = '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados';
      forceClose = await this.dialogService.yesNoOptionModal(this.title, message, 'cancel');
    }
    if (forceClose === 'cancel') {
      try {
        this.loadingService.setLoading(true);
        this.incidentService.tabs.removeActiveMethod();
        const data: PatientHandlingAndManagementInput['data'] = this.assignValuesToSent();
        await this.incidentsEventsService.cancelPatientHandlingAndManagement(
          data,
          this.data.tabData.selectedIncident?.id as number
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingService.setLoading(false);
        this.dialogRef.close();
        await this.incidentService.activeIncidentLogs?.getAllLogs();
      }
    } else if (forceClose === '') {
      this.dialogRef.close();
    }
  }

  /**
   * If the user selects the option "Yes" in the field "Transfer Required", the fields "Destination IPS", "IPS Address" and
   * "Contact Phone" are disabled and the field "Required Resource APH" is enabled
   */
  changedTransferRequired(): void {
    const noTransferRequiredFields = ['noTransferReason', 'noTransferCause'];
    const transferRequiredFields = ['destinationIPS', 'requiredResourceAPH'];

    if (this.registerManagementAddressingForm.get('transferRequired')?.value === 2) {
      this.registerManagementAddressingForm.get('destinationIPS')?.setValue('');
      this.registerManagementAddressingForm.get('IPSAddress')?.setValue('');
      this.registerManagementAddressingForm.get('contactPhone')?.setValue('');
      this.registerManagementAddressingForm.get('IPSAddress')?.disable();
      this.registerManagementAddressingForm.get('contactPhone')?.disable();
      this.registerManagementAddressingForm.get('contactPhone')?.disable();

      for (const field of transferRequiredFields) {
        this.registerManagementAddressingForm.get(field)?.clearValidators();
        this.registerManagementAddressingForm.get(field)?.updateValueAndValidity();
      }

      for (const field of noTransferRequiredFields) {
        this.registerManagementAddressingForm.get(field)?.setValidators([Validators.required]);
        this.registerManagementAddressingForm.get(field)?.updateValueAndValidity();
      }
    } else {
      this.registerManagementAddressingForm.get('noTransferReason')?.setValue('');
      this.registerManagementAddressingForm.get('noTransferCause')?.setValue('');

      for (const field of transferRequiredFields) {
        this.registerManagementAddressingForm.get(field)?.setValidators([Validators.required]);
        this.registerManagementAddressingForm.get(field)?.updateValueAndValidity();
      }

      for (const field of noTransferRequiredFields) {
        this.registerManagementAddressingForm.get(field)?.clearValidators();
        this.registerManagementAddressingForm.get(field)?.updateValueAndValidity();
      }
      this.alertIPSActive = false;

      this.hasPriority[1].priority = false;
    }
  }

  /**
   * It sets the value of the destinationIPS form control to the value of the ipsId parameter, and if the ipsId parameter
   * is not an empty string, it sets the value of the IPSAddress form control to the addressHeadquarters property of the
   * providerIPS array element whose id property matches the ipsId parameter, and if the providerIPS array element has a
   * contactData property, it sets the value of the contactPhone form control to the cellphone property of the first
   * element of the contactData array
   * @param {number | string} ipsId - number | string: The id of the IPS that is selected in the dropdown.
   * @returns the value of the form control 'destinationIPS'
   */
  setDestinationIPS(ipsId: number | string): void {
    this.registerManagementAddressingForm.get('destinationIPS')?.setValue(ipsId);
    if (ipsId === '') return;
    const provider = this.filteredIps.find((provider) => provider.id === ipsId);
    if (!provider) return;
    this.registerManagementAddressingForm.get('IPSAddress')?.setValue(provider.addressOffice);
    if (provider.contactData && provider.contactData[0].cellphone !== null) {
      this.registerManagementAddressingForm
        .get('contactPhone')
        ?.setValue(provider.contactData[0].cellphone);
    } else {
      this.registerManagementAddressingForm
        .get('contactPhone')
        ?.setValue(
          provider.contactData && provider.contactData[1] ? provider.contactData[1].cellphone : ''
        );
    }
    this.registerManagementAddressingForm.get('contactPhone')?.disable();
    this.registerManagementAddressingForm.get('IPSAddress')?.disable();
  }

  /**
   * It assigns the values of the form to the object that will be sent to the API
   * @returns an object with the values of the form.
   */
  private assignValuesToSent(): PatientHandlingAndManagementInput['data'] {
    return {
      alertIPS: this.alertIPSActive,
      handlingRecommendations:
        this.registerManagementAddressingForm.get('managementIndications')?.value &&
        this.registerManagementAddressingForm.get('managementIndications')?.value !== ''
          ? this.registerManagementAddressingForm.get('managementIndications')?.value
          : undefined,
      handlingRecommendationsAsPriority: this.hasPriority[0].priority,
      handlingResourceTypeId:
        this.registerManagementAddressingForm.get('requiredResourceAPH')?.value &&
        this.registerManagementAddressingForm.get('requiredResourceAPH')?.value !== ''
          ? this.registerManagementAddressingForm.get('requiredResourceAPH')?.value
          : undefined,
      mainDiagnosisId:
        this.registerManagementAddressingForm.get('validatePrincipalDiagnosis')?.value &&
        this.registerManagementAddressingForm.get('validatePrincipalDiagnosis')?.value !== ''
          ? this.registerManagementAddressingForm.get('validatePrincipalDiagnosis')?.value
          : this.registerManagementAddressingForm.get('principalDiagnosis')?.value,
      noTransferCauseId:
        this.registerManagementAddressingForm.get('noTransferCause')?.value &&
        this.registerManagementAddressingForm.get('noTransferCause')?.value !== ''
          ? this.registerManagementAddressingForm.get('noTransferCause')?.value
          : undefined,
      optionalDiagnosisId:
        this.registerManagementAddressingForm.get('validateOptionalDiagnosis')?.value &&
        this.registerManagementAddressingForm.get('validateOptionalDiagnosis')?.value !== ''
          ? this.registerManagementAddressingForm.get('validateOptionalDiagnosis')?.value
          : undefined,
      reasonForNoTransferRequired:
        this.registerManagementAddressingForm.get('noTransferReason')?.value &&
        this.registerManagementAddressingForm.get('noTransferReason')?.value !== ''
          ? this.registerManagementAddressingForm.get('noTransferReason')?.value
          : undefined,
      reasonForNoTransferRequiredAsPriority: this.hasPriority[1].priority,
      secondOptionalDiagnosisId:
        this.registerManagementAddressingForm.get('validateOptionalDiagnosis2')?.value &&
        this.registerManagementAddressingForm.get('validateOptionalDiagnosis2')?.value !== ''
          ? this.registerManagementAddressingForm.get('validateOptionalDiagnosis2')?.value
          : undefined,
      transferRequired: this.registerManagementAddressingForm.get('transferRequired')?.value === 1,
      transferToIPSId:
        this.registerManagementAddressingForm.get('destinationIPS')?.value &&
        this.registerManagementAddressingForm.get('destinationIPS')?.value !== ''
          ? this.registerManagementAddressingForm.get('destinationIPS')?.value
          : undefined,
    };
  }

  /**
   * The function closes the modal and returns a boolean value to the parent component
   * @param {boolean} [addManagement=false] - boolean = false
   */
  closeModal(addManagement: boolean = false) {
    this.dialogRef.close(addManagement);
  }

  /**
   * It validates the form, assigns the values to a variable, and then saves the data to the database
   */
  async saveHandlingRouting() {
    if (!this.canContinue) return;
    this.canContinue = false;
    if (!(await this.permissionsValidatorService.hasAccessToSection(['handling_management_w']))) {
      await this.dialogService.unauthorizedErrorModal(this.profileService.selectedRole!);
      this.canContinue = true;
      return;
    }
    const validateForm = await this.validateData();
    if (!validateForm) {
      this.canContinue = true;
      return;
    }
    this.saveData = true;
    this.loadingService.setLoading(true);
    const body: PatientHandlingAndManagementInput['data'] = this.assignValuesToSent();
    if (this.data.tabData.selectedIncident && this.data.patient) {
      try {
        await this.incidentService.registerManagementAddressing(
          this.data.tabData.selectedIncident.id,
          this.data.patient.id,
          body
        );
        await this.copyData(false, body);
        this.loadingService.setLoading(false);
        await this.dialogService.successModal(
          'La información ha sido almacenada exitosamente y se encuentra disponible en el portapapeles',
          this.data.title
        );
        this.incidentService.activeIncidentLogs?.getAllLogs();
        this.saveData = false;
        this.closeModal(true);
      } catch (e: any) {
        this.loadingService.setLoading(false);
        console.error(e);
        let message = 'Ocurrió un error al guardar los datos, intente de nuevo.';
        if (
          e.error.error.includes(
            "if 'transferRequired' is set to true 'handlingResourceTypeId' should be passed"
          )
        ) {
          message = 'El tipo de recurso es requerido.';
        }
        if ((e as any)?.error?.error?.includes('noTransferCause')) {
          message =
            'La paramétrica "Motivo de No Traslado" no se encuentra registrada en el sistema.';
        }
        await this.dialogService.errorModal(this.data.title, message);
      }
    }
    this.loadingService.setLoading(false);
    this.canContinue = true;
  }

  /**
   * It validates the form and returns a boolean value
   * @returns A boolean value.
   */
  private async validateData() {
    if (this.registerManagementAddressingForm?.invalid) {
      const step1List = this.registerManagementAddressingForm.controls;
      let invalidList = [];
      for (const property in step1List) {
        const controlState = this.registerManagementAddressingForm.get(property)?.invalid;
        if (controlState) {
          invalidList.push(property);
          this.registerManagementAddressingForm.get(property)!.markAsTouched();
          this.managementAddressingFormTouched = true;
        }
      }
      if (invalidList.find((prop) => prop === 'transferRequired')) {
        invalidList = invalidList.filter(
          (prop) => prop !== 'destinationIPS' && prop !== 'requiredResourceAPH'
        );
      }
      const uncompletedList = invalidList.map((property) => this.formNames[property]).join(', ');
      await this.dialogService.infoSubtitledModal(
        this.data.title,
        'Faltan los siguientes campos por diligenciar',
        uncompletedList
      );
      invalidList[0] === 'requiredResourceAPH'
        ? this.requiresResource.nativeElement.scrollIntoView()
        : this.diagnosticValidation.nativeElement.scrollIntoView();
      return false;
    }
    return true;
  }

  /**
   * copy text generated to clipboard
   * @param fromButton
   * @param handlingAndManagement
   */
  public async copyData(
    fromButton: boolean = false,
    handlingAndManagement?: PatientHandlingAndManagementInput['data']
  ) {
    const values = !handlingAndManagement ? this.assignValuesToSent() : handlingAndManagement;
    const resource = this.data.tabData.aphResourceType.find(
      (resourceType) => resourceType.id === values.handlingResourceTypeId
    );
    const noTransferCauses = this.data.tabData.noTransferCauses.find(
      (transferCause) => transferCause.id === values.noTransferCauseId
    );
    const firstDiagnostic = this.diagnosis.find(
      (diagnostic) => diagnostic.id === values.mainDiagnosisId
    );
    const optionalDiagnostic = this.diagnosis.find(
      (diagnostic) => diagnostic.id === values.optionalDiagnosisId
    );
    const secondDiagnostic = this.diagnosis.find(
      (diagnostic) => diagnostic.id === values.secondOptionalDiagnosisId
    );
    let ips;
    if (values.transferToIPSId !== null && values.transferToIPSId !== undefined) {
      ips = this.filteredIps.find(
        (provider) => Number(provider.id) === Number(values.transferToIPSId)
      )!;
    }
    const confirmBody = {
      'MANEJO Y DIRECCIONAMIENTO': {
        'DIAGNOSTICO PRINCIPAL':
          this.data.patient?.diagnosticImpression !== null &&
          this.data.patient?.diagnosticImpression !== undefined
            ? this.data.patient?.diagnosticImpression.title
            : '',
        'VALIDAR DIAGNOSTICO PRINCIPAL': firstDiagnostic?.title || '',
        'DIAGNOSTICO OPCIONAL 1':
          this.data.patient?.secondDiagnosis !== null &&
          this.data.patient?.secondDiagnosis !== undefined
            ? this.data.patient?.secondDiagnosis.title
            : '',
        'VALIDAR DIAGNOSTICO OPCIONAL 1': optionalDiagnostic?.title || '',
        'DIAGNOSTICO OPCIONAL 2':
          this.data.patient?.secondDiagnosis !== null &&
          this.data.patient?.secondDiagnosis !== undefined
            ? this.data.patient?.secondDiagnosis.title
            : '',
        'VALIDAR DIAGNOSTICO OPCIONAL 2': secondDiagnostic?.title || '',
        'INDICACIONES DE MANEJO': values.handlingRecommendations
          ? values.handlingRecommendations
          : '',
        'INDICACIONES DE MANEJO PRIORITARIA': values.handlingRecommendationsAsPriority
          ? 'Si'
          : 'No',
        'AMERITA TRASLADO': values.transferRequired ? 'Si' : 'No',
        'RAZÓN DE NO MERITO DE TRASLADO': !values.transferRequired
          ? values.reasonForNoTransferRequired
            ? values.reasonForNoTransferRequired
            : ''
          : '',
        'NO MERITO DE TRASLADO': !values.transferRequired ? noTransferCauses?.title || '' : '',
        'IPS DESTINO': values.transferRequired ? ips?.title || '' : '',
        'DIRECCION IPS': values.transferRequired ? ips?.addressOffice || '' : '',
        'TELÉFONO DE CONTACTO': values.transferRequired
          ? ips?.contactData
            ? ips.contactData[0].cellphone
            : ''
          : '',
        'TIPO DE RECURSO': values.transferRequired ? resource?.title || '' : '',
      },
    };
    this.clipboard.copy(JSON.stringify(confirmBody));
    if (fromButton)
      await this.dialogService.successModal(
        'La información se encuentra disponible en el portapapeles',
        this.title
      );
  }

  /**
   * Disables specific controls in the `registerManagementAddressingForm` form group.
   * The controls that will be disabled are:
   * - `principalDiagnosis`
   * - `optionalDiagnosis1`
   * - `optionalDiagnosis2`
   * - `IPSAddress`
   * - `contactPhone`
   *
   * @returns {void} This function does not return a value.
   */
  setDefaultDisabledControls() {
    this.registerManagementAddressingForm.get('principalDiagnosis')?.disable();
    this.registerManagementAddressingForm.get('optionalDiagnosis1')?.disable();
    this.registerManagementAddressingForm.get('optionalDiagnosis2')?.disable();
    this.registerManagementAddressingForm.get('IPSAddress')?.disable();
    this.registerManagementAddressingForm.get('contactPhone')?.disable();
  }
}
